import { render, staticRenderFns } from "./addressList.vue?vue&type=template&id=7ed6d684&scoped=true&"
import script from "./addressList.vue?vue&type=script&lang=js&"
export * from "./addressList.vue?vue&type=script&lang=js&"
import style0 from "./addressList.vue?vue&type=style&index=0&id=7ed6d684&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed6d684",
  null
  
)

export default component.exports